import { SVGInjector } from "../../node_modules/@tanem/svg-injector/dist/svg-injector.esm.js";

const svgs = document.querySelectorAll(`img.svg, img[src*=".svg"]`);

SVGInjector(svgs, {
  afterAll(elementsLoaded) {
  },
  afterEach(err, svg) {
    if (err) {
      throw err
    }
  },
  beforeEach(svg) {
  },
})
