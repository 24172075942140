document.addEventListener("click", function(event) {
  let anchor = /** @type HTMLAnchorElement */(event.target);

  if (anchor = anchor.closest(`a.button-media[href*="youtu"], a.button-media[href*="vimeo.com"]`)) {
    const editor = anchor.closest(".mce-content-body");
    if (editor) {
      return;
    }

    event.preventDefault();
    const thumbnail = anchor.querySelector("img");
    const width = thumbnail.getAttribute("width");
    const height = thumbnail.getAttribute("height");
    const classes = anchor.getAttribute("class");
    // URL is polyfilled with polyfill.io
    const { hostname, searchParams, pathname } = new URL(anchor.getAttribute("href"));
    let id, src;

    if (hostname === "youtube.com" || hostname === "www.youtube.com") {
      src = `https://www.youtube.com/embed/${searchParams.get("v")}?autoplay=1&rel=0`;
    } else if (hostname === "youtu.be" || hostname === "www.youtu.be") {
      src = `https://www.youtube.com/embed${pathname.substr(0)}?autoplay=1&rel=0`;
    } else if (hostname === "vimeo.com" || hostname === "www.vimeo.com") {
      src = `https://player.vimeo.com/video${pathname.substr(0)}?autoplay=1`;
    }

    const video = document.createElement("div");
    video.setAttribute("class", classes);
    video.innerHTML = `<iframe
      width="${width}" height="${height}" frameborder="0" allowfullscreen
      src="${src}"
      allow="accelerometer; autoplay; fullscreen; encrypted-media; gyroscope; picture-in-picture"
    ></iframe>`;

    anchor.replaceWith(video);
  }
});
