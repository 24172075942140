import dialogPolyfill from "../../node_modules/dialog-polyfill/dist/dialog-polyfill.esm.js";
import { SVGInjector } from "../../node_modules/@tanem/svg-injector/dist/svg-injector.esm.js";

const YOUTUBE_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
const SUPPORTED_EXTENSION_REGEX = /\.(mp4|webm|jpg|png|svg)$/

//#region Dialogs
const dialogs = document.querySelectorAll("dialog");
for (let i = 0, count = dialogs.length; i < count; i++) {
  dialogPolyfill.registerDialog(dialogs[i]);
}
//#endregion

//#region Lightbox
const lightbox = /** @type HTMLDialogElement */ (document.getElementById("lightbox"));

if (lightbox) {
  const container = lightbox.firstElementChild;
  const closeButton = lightbox.querySelector(`[type="submit"]`);

  function empty(element) {
    // Just in case it's faster to check for firstChild than lastChild
    // (e.g. if the element list is implemented as a directed linked-list by the UA).
    while (element.firstChild) {
      // In general, it's significantly faster to remove the last element of a collection
      // than it is to remove the first element.
      element.removeChild(element.lastChild);
    }
  }

  document.addEventListener("click", event => {
    let target = /** @type HTMLAnchorElement */(event.target);

    if (target = target.closest(`a[href][rel*="lightbox"]`)) {

      const editor = target.closest(".mce-content-body");
      if (editor) {
        return;
      }

      event.preventDefault();
      const { hostname, searchParams, pathname = "", hash } = new URL(target.href);
      let videoId, content = "";

      switch (hostname) {
        case "":
        case location.hostname:
          if (hash) {
            const element = document.querySelector(hash);
            if (element) {
              content = element.innerHTML;
            }
            break;
          }

          const [, extension] = pathname.match(SUPPORTED_EXTENSION_REGEX) || [];
          switch (extension) {
            case "mp4":
            case "webm":
              content = `<figure class="media">
                <video preload="metadata" autoplay="autoplay" loop="loop" controls="controls">
                  <source src="${ pathname }" type="video/${ extension }" />
                </video>
              </figure>`;
              break;
            case "jpg":
            case "png":
            case "svg":
              content = `<figure class="media">
                <img src="${ pathname }" />
              </figure>`;
              break;
          }

          break;
        case "youtube.com":
        case "www.youtube.com":
          //videoId = searchParams.get("v");
          videoId = target.href.match(YOUTUBE_REGEX)[1]
          if (videoId) {
            content = `<div class="aspect-ratio-16/9">
            <iframe
              width="900" height="450" frameborder="0"  allowfullscreen
              src="https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&rel=0&enablejsapi=1"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
            </div>`;
          }
          break;
        case "youtu.be":
        case "www.youtu.be":
          videoId = pathname.substr(1);

          if (videoId) {
            content = `<div class="aspect-ratio-16/9">
            <iframe
              width="900" height="450" frameborder="0"  allowfullscreen
              src="https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&rel=0&enablejsapi=1"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
            </div>`;
          }
          break;
        case "vimeo.com":
        case "www.vimeo.com":
          videoId = pathname.substr(1);

          if (videoId) {
            content = `<div class="aspect-ratio-16/9">
              <iframe src="https://player.vimeo.com/video/${ videoId }?autoplay=1&title=0&byline=0&portrait=0"
                width="900" height="450" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
              <div>`;
          }
          break;
        default:
          content = `<iframe src="${ target.href }" style="width:800px; height: 90vh;"></iframe>`;
          break;
      }

      empty(container);

      container.insertAdjacentHTML("beforeend", content);
      container.appendChild(closeButton);

      SVGInjector(lightbox.querySelectorAll(`img.svg, img[src*=".svg"]`), {
        beforeEach(svg) {
          const viewBox = svg.viewBox.baseVal;
          if (!svg.hasAttribute("width")) {
            svg.setAttribute("width", viewBox.width);
          }

          if (!svg.hasAttribute("height")) {
            svg.setAttribute("height", viewBox.height);
          }
        }
      });

      lightbox.showModal();
    }
  });

  // Handle click on the backdrop to close the modal.
  lightbox.addEventListener("click", event => {
    // Clicking on the backdrop.
    if (event.target == lightbox) {
      lightbox.close();
    }
  });

  lightbox.addEventListener("close", event => {
    empty(container);
  });
}
//#endregion
