// Use JS to make adjustment to button's perspective.
const resizeObserver = new ResizeObserver((entries, observer) => {
  entries.forEach((entry) => {
    const {target, contentRect} = entry;
    const {left: paddingLeft, width} = contentRect;

    target.style.perspective = `${((width + paddingLeft * 2) / 180).toFixed(2)}em`;
  });
});

const buttons = document.querySelectorAll(".button, .button-secondary");
for (let i = 0, count = buttons.length; i < count; i++) {
  resizeObserver.observe(buttons[i]);
}
